import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import jaLocale from "@fullcalendar/core/locales/ja";
import { DateTime } from "luxon";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";

const windowWidth = window.innerWidth;
const isSpWidth = windowWidth < 426;

const isActiveEllipsis = (el) => {
  return el.clientWidth < el.scrollWidth;
};

const eventClick = (event) => {
  const { url } = event.event;
  const { jsEvent } = event;
  if (!!url) {
    jsEvent.preventDefault();
    window.open(url, "_blank");
    return;
  }
};

const titleFormat = ({ start, end }) => {
  const startDt = DateTime.local(start.year, start.month + 1, start.day);
  const endDt = DateTime.local(end.year, end.month + 1, end.day);

  if (isSpWidth) {
    return `${startDt.toLocaleString()} - ${endDt.day}`;
  }

  return startDt.toLocaleString({ year: "numeric", month: "numeric" });
};

const eventDidMount = ({ el, event }) => {
  const eventTitleEl =
    el.querySelector(".fc-event-title") ||
    el.querySelector(".fc-list-event-title");

  if (isActiveEllipsis(eventTitleEl)) {
    tippy(el, {
      content: event.title,
      arrow: true,
      theme: "light",
    });
  }
};

document.addEventListener("DOMContentLoaded", () => {
  const calendarEl = document.querySelector("#game-schedule-calendar");
  if (calendarEl === null) return;

  const calendar = new Calendar(calendarEl, {
    locales: [jaLocale],
    plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
    initialView: isSpWidth ? "listWeek" : "dayGridMonth",
    headerToolbar: {
      left: "prev",
      center: "title",
      right: "next",
    },
    eventClick,
    titleFormat,
    eventDidMount,
    dayMaxEventRows: true,
    eventDataTransform: (event) => {
      if (event.url === null) delete event.url;
      console.log(event);
      return event;
    },
    eventBackgroundColor: "transparent",
    eventBorderColor: "#1a3150",
    eventTextColor: "#1a3150",
    events: "/tournaments",
  });

  calendar.render();
});
